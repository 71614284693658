import React, { useState } from 'react';
import axios from 'axios'
import './login.css'
import { Form, Button, Container, Row, Col, ToastContainer } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { isLoginUpdater } from '../../redux/actions/action';
import { Link } from 'react-router-dom';
const Login = () => {
  // checks if admin is login then show dashboard page or shows login page.
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const handleSubmit = async(e) => {
      e.preventDefault();
      if(!(email && password.length>=8)) return null;
       //  API calls here
       try{
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/login`,{email:email.trim(),password:password.trim()});
        setError('')
        document.cookie = `token=${response.data.token}`
        dispatch(isLoginUpdater(true))
      }catch(e){
        setError(e.response?.data?.error)
      }

    };
  
    return (<Container className="loginClass ">
    <ToastContainer
      position={`bottom-end`}
      style={{ zIndex: 1 }}
    >
    </ToastContainer>
  <Row className="justify-content-md-center">
    <Col xs={12} sm={10} md={6} xxl={5}>
      <h2 className="text-center">Admin Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)} 
            autoComplete='true'
          />
          <Form.Control.Feedback className='p-2' type='Invalid'>
            {error}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100 mt-4">
          Login
        </Button>
        <Link className='forgotPassword' to='/admin/forgot-password'>forgot password</Link>
      </Form>
    </Col>
  </Row>
  </Container>)
}

export default Login
