import React from 'react'
import './updatePdf.css'
import { useRef, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'

const UpdatePdf = () => {
    const dateRef = useRef(null)
    const [file, setFile] = useState(null);
    const [currentPdf, setCurrentPdf] = useState(null);
    const [date, setDate] = useState(null);
    const [step, setStep] = useState(1); //managing which input field should render on UI
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setLoading] = useState(false);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSize = 20 * 1024 * 1024; // 20 MB
        const allowedTypes = ['application/pdf'];
        // form validations
        if (selectedFile) {
            if (!allowedTypes.includes(selectedFile.type)) {
                setError('Only PDF files are allowed.');
                setFile(null);
            } else if (selectedFile.size > maxSize) {
                setError('File size exceeds the 20 MB limit.');
                setFile(null);
            } else {
                setError('');
                setFile(selectedFile);
            }
        }
    };
    const handleDateChecker = async (event) => {
        event.preventDefault()
        console.log(date);
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/file-status`, { date });
        if (response?.data?.data) {
            setLoading(false)
            setCurrentPdf(response?.data?.data)
            setSuccess(`Pdf Available for ${date}`)
            setTimeout(() => setSuccess(null), 4000)

            setStep(2);
        } else {
            setError(`for ${date} pdf is not uploaded`)
            setTimeout(() => setError(null), 5000);

        }

    }
    const handleFileUpdate = async (event) => {
        event.preventDefault();
        if (file) {
            // Handle update file logic here
            const formData = new FormData();
            formData.append('file', file);
            formData.append('date', date);


            try {
                setLoading(true)
                const fileRes = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/update-pdf`, formData);

                if (fileRes) {
                    setLoading(false)
                    setSuccess(`your PDF updated successfully!`)
                    setTimeout(() => setSuccess(null), 4000)
                }

            } catch (error) {

            }
        }
    }

    return (
        <Container>
            <Row className='justify-content-md-center'>
                <Col md={6}>
                    <h2>Existing File Update</h2>
                    <Form className='border border-1 bg-light' encType={'multipart/form-data'} onSubmit={step === 1 ? handleDateChecker : handleFileUpdate}>
                        <Form.Group controlId="formFile" className="mb-3">
                            {
                                step === 1 && (<>
                                    <Form.Label>Select a date for Update pdf</Form.Label>
                                    <Form.Control type='date' onChange={(e) => setDate(e.target.value)} name='date' required />
                                </>)
                            }

                            {
                                step === 2 && (<>
                                    <div className='m-md-3 m-1'>{currentPdf && <Link to={currentPdf.path} className='text text-decoration-none bg-light p-1' target='_brank' >click here to see old pdf for {date}</Link>}</div>
                                    <Form.Label>Select a new file to update with above one</Form.Label>
                                    <Form.Control type="file" name='file' onChange={handleFileChange} />
                                </>)
                            }
                        </Form.Group>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}
                        {step >= 2 && (<Button className='me-1' onClick={() => { setStep(1) }}>
                            Prev
                        </Button>)}
                        <Button type="submit" disabled={(isLoading === false || currentPdf === (null || undefined)) ? false : true}>
                            {isLoading === true ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Processing...
                                </>
                            ) : ''}Update Pdf
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default UpdatePdf
