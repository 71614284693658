import React from 'react'
import { useParams } from 'react-router-dom'
const Categories = () => {
    const {id} = useParams()
  return (
    <div>
      This is special is: {id}
    </div>
  )
}

export default Categories
