import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
const NotFound = () => {
  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row className="text-center">
        <Col>
          <h1 className="display-1 text-danger">404</h1>
          <h2 className="mb-4">Oops! Page Not Found</h2>
          <p className="mb-5">
            Sorry, the page you are looking for does not exist. It might have been moved or deleted.
          </p>
          <Link to="/">
            <Button variant="primary" size="lg">
              Go Back Home
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound
