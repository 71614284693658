export const adminHeader = [
    {
        id:1,
        data:'Upload',
    },
    {
        id:2,
        data:'Update Pdf',
    },
    {
        id:3,
        data:'Forgot Password',
    },
    {
        id:4,
        data:'Appearance',
    },
    {
        id:5,
        data:'logout',
    }
]