import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './styles/header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const storedData = localStorage.getItem('data');
  const [data, setData] = useState(() => {
    return storedData ? JSON.parse(storedData) : null; // Fallback to null if no data
  });

  const fetchHeaderData = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/visual/visual`);
      const response = result?.data?.data;
      setData(response);
      localStorage.setItem('data', JSON.stringify(response));
    } catch (error) {
      console.error('Error fetching header data:', error);
    }
  };

  const isValidData = async (url) => {
    try {
      // Check for header image 
      await axios.get(url);
    } catch (error) {
      // Optionally fetch header data if validation fails
      fetchHeaderData();
    }
  };

  useEffect(() => {
    if (!storedData) {
      fetchHeaderData();
    } else {
      isValidData(`${process.env.REACT_APP_BACKEND_URL}api/v1/visual/check/${data.headerImageId}`);
    }
  }, [storedData]);

  return (
    <Navbar expand="md" className={'Navbar'}>
      <Container fluid>
        <Navbar.Brand>
          <Link className={'linkHeader'} to={'/'}>
            {data?.headerName || 'vadal-varta'}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '150px' }}
            navbarScroll
          >
            <Nav.Link as={Link} className='linkBody' to={'/'}>Home</Nav.Link>
            {
              data?.navTab?.map(({ title, url }, ind) => (
                <Nav.Link key={`link_${ind}`} href={url} target='_blank' className={'linkBody'}>
                  {title}
                  {/* <a href={url} target='_blank' rel="noopener noreferrer">{title}</a> */}
                </Nav.Link>
              ))
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
