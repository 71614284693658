  import React, { useEffect, useState } from 'react'
  import { Container, Row, Col, Nav } from 'react-bootstrap';
  import './admin.css'
  import {Login} from '../../components/login'
  import { PasswordComp } from '../../components/passwordComp';
  import {Appearance} from '../../components/appearance';
  import Upload from '../../components/upload';
  import { UpdatePdf } from '../../components/updatePdf';
  import { adminHeader } from '../../utils/adminDashboard';
  import { useDispatch,useSelector} from 'react-redux';
  import { isLoginUpdater } from '../../redux/actions/action';
import Header from '../../components/header';
  const Admin = () => {
      const dispatch = useDispatch();
      const isLogin = useSelector(state=>state.appReducer.isLogin);
      const [currentComp,setCurrentComp] = useState(<Upload/>);

      // useEffect for detect the change
      useEffect(()=>{
        
      },[isLogin])

      const handleAdminNavigation = (e)=>{
        const clickRef = e.target.innerHTML?.toLowerCase();
        switch(clickRef){
          case 'upload':
              return setCurrentComp(<Upload/>)
          case 'forgot password':
              return setCurrentComp(<PasswordComp/>)
          case 'update pdf':
              return setCurrentComp(<UpdatePdf/>)
          case 'appearance':
              return setCurrentComp(<Appearance/>)
          case 'logout':
            document.cookie = `token=; expires=Thu, 01-Jan-2024 00:00:01 GMT;`;
            dispatch(isLoginUpdater(false))
            return <Login/>
          default: return <Login/>
        }
        
      }
    return (
      <Container fluid>
        <Header/>
      <Row>
        <Col md={2} className="bg-light sidebar">
          <Nav className="flex-column">
            {
              adminHeader.map(({id,data})=>{
                return  <Nav.Link  style={{height:'100%', minWidth:'30px'}} key={id} onClick={handleAdminNavigation} >{data}</Nav.Link>
              })
            }
          </Nav>
        </Col>
        <Col md={10} className="pt-5">
          {/* dynamic component redering */}
          {currentComp}
        </Col>
      </Row>
    </Container>
    )
  }

  export default Admin
