import React, { useState } from 'react';
import { Container,Button, DropdownButton, Dropdown, Row, Col, Modal, Form, ButtonGroup } from 'react-bootstrap';
import { MAX_DATE,MIN_DATE } from '../../utils/date';
const ArchiveComponent = ({ handleSetPdf, dateRef, setPageNumber, pageNumber, total,getPdf }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const handleArchiveClick = () => {
        setShowCalendar(true);
    };

    const handleClose = () => {
        setShowCalendar(false);
    };

      const handleDownload = () => {
    const link = `${getPdf===null?'./mypdf.pdf':getPdf}`;
    window.open(link,'_blank')

  };
    const goToNextPage = () => {
        if (pageNumber < total) {
            setPageNumber(prevPage => prevPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(prevPage => prevPage - 1);
        }
    };
    const dropDownArray = Array.from({ length: total }, (v, i) => i + 1);
    return (
        <Container className='px-0 px-md-5' style={{ backgroundColor: `rgba(12, 12, 12, 0.033)`}}>
            <Row className="align-items-center justify-content-between">
                <Col md="auto" xs={12}>
                    <DropdownButton variant='primary' className='text text-center' id="dropdown-basic-button"  title={`Page ${pageNumber}`}>
                        {
                            dropDownArray?.map(val => <Dropdown.Item key={`dropdown${val}`} onClick={() => setPageNumber(val)} >Page {val}</Dropdown.Item>)
                        }

                    </DropdownButton>
                   
                </Col>

                {/* Pagination */}
                <Col md="auto">
                    <div className="d-none d-md-flex overflow-x-auto" style={{maxWidth:'400px'}}>
                        <Button className='me-1 '  variant={pageNumber===1?'light':'primary'} onClick={goToPrevPage} disabled={pageNumber===1}>&laquo;</Button>
                        {
                            dropDownArray?.map((val)=><Button  key={`btn${val}`} variant={pageNumber===val?'primary':''}   onClick={()=>setPageNumber(val)}>{val}</Button>)
                        }
                        <Button className='ms-1' variant={pageNumber===total?'light':'primary'} onClick={goToNextPage} disabled={pageNumber===total}>&raquo;</Button>
                    </div>
                </Col>

                <Col md="auto" className="d-flex mt-1 justify-content-center">
                    
                    <ButtonGroup className='d-flex justify-content-between' >
                    <Button  variant="danger" className="me-2" onClick={handleDownload} target='_blank'><img  src='./pdf.svg' /> PDF</Button>
                    {/* <Button variant="primary" className="me-2 p-2 "><img src='./scissors.svg' style={{marginRight:'2px'}} /> Clip </Button> */}
                    <Button variant="primary"  className="me-2 p-2 "onClick={handleArchiveClick}> <img style={{marginRight:'2px'}}  src='./calendar2.svg' /> Archive</Button>
                    </ButtonGroup>
                </Col>
            </Row>

            {/* Calendar Modal */}
            <Modal show={showCalendar} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date for News</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="date" ref={dateRef} min={MIN_DATE} max={MAX_DATE} onChange={handleSetPdf} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ArchiveComponent;
