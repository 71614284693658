import React, { useState,useEffect } from 'react'
import { Login } from '../../components/login';
import readCookie from '../../utils/readCookie';
import Admin from './admin';
import { useSelector } from 'react-redux';
import Header from '../../components/header';
const AdminDashboard = () => {
const [status,setStatus] = useState(readCookie('token'))  
const isLogin = useSelector(state=>state.appReducer.isLogin);
    // useEffect help to re-render component if state changes
    useEffect(()=>{
        setStatus(readCookie('token'))
    },[isLogin])
  // conditional rendering
  if(status)
      return <Admin/>
  else
      return <><Header/><Login/></>
}
export default AdminDashboard
