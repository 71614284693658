import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col,Alert } from 'react-bootstrap';
const Upload = () => {
  const dateRef = useRef(null)
  const [file, setFile] = useState(null);
  const [date,setDate]=  useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize =  20* 1024 * 1024; // 20 MB
    const allowedTypes = ['application/pdf'];
    // form validations
    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setError('Only PDF files are allowed.');
        setFile(null);
      } else if (selectedFile.size > maxSize) {
        setError('File size exceeds the 20 MB limit.');
        setFile(null);
      } else {
        setError('');
        setFile(selectedFile);
      }
    }
  };


  // submiting logic
  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      // Handle file upload logic here
      const formData = new FormData();
      formData.append('file',file);
      formData.append('date',date);
      
      axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/upload`,formData)
      .then(res=>{
        setSuccess(res?.data?.message);
        setTimeout(()=>{
          setSuccess('');
        },3000)
      })
      .catch(res=>{
        console.log('failure res',res);
        setError(res.response.data.message)
        setTimeout(()=>{
          setError('')
          dateRef.current.value = null;
        },3000)
      })
    } else {
      console.log('No file selected');
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2>Upload a File</h2>
          <Form encType={'multipart/form-data'} onSubmit={handleSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select a date for display pdf</Form.Label>
              <Form.Control type='date' ref={dateRef} onChange={(e)=>setDate(e.target.value)} name='date' required/>
              <Form.Label>Select a file to upload</Form.Label>
              <Form.Control type="file"   name='file' onChange={handleFileChange} />
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Button variant="primary" type="submit" disabled={!file}>
              Upload
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Upload
