import React, { useRef, useState } from 'react'
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
const Appearance = () => {
    const [fields, setFields] = useState([{ title: '', url: '' }]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageN, setErrorMessageN] = useState('');
    const [successMessageN, setSuccessMessageN] = useState('');
    const [successMessages,setSuccessMessages] = useState({fileUpload:'', header:'',tab:''})
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[0];
            if (fileType !== 'image') {
                setErrorMessage('Please upload only image files.');
                setTimeout(() => setErrorMessage(''), 3000)
                setSelectedFile(null);
            } else {
                setErrorMessage('');
                setSelectedFile(file);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedFile) {
            // Submit form logic
            const formData = new FormData();
            formData.append('headerLogo', selectedFile)
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}api/v1/visual/header-logo-update`, formData);
            setSuccessMessages(prev=>({...prev,fileUpload:'logo updated successfully!'}));
            setTimeout(() => {
                setSuccessMessages(prev=>({...prev,fileUpload:''}));
            }, 3000)
            console.log(response.data);

        } else {
            setErrorMessage('No file selected or invalid file type.');
        }
    };
    const headerRef = useRef(null);

    const handleHeaderSubmit=async(e)=>{
        e.preventDefault();
        const newHeaderValue = headerRef?.current?.value;
        if(`${newHeaderValue}`.length <3){
            setErrorMessageN('please provide name at least 3 letters');
            setTimeout(() => {
                setErrorMessageN('');
            }, 3000)
        }else{
            try {
                const res = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}api/v1/visual/header-name`,{header:newHeaderValue});
                setSuccessMessageN('header updated successfully')
                setTimeout(() => {
                    setSuccessMessageN('');
                }, 3000)
            } catch (error) {
                setErrorMessageN(`${error.message}`)
                setTimeout(() => {
                    setErrorMessageN('');
                }, 3000)
            }   
        }
    }


    // Handle change for individual field inputs
    const handleInputChange = (index, event) => {
        const newFields = [...fields];
        newFields[index][event.target.name] = event.target.value;
        setFields(newFields);
    };

    // Add a new set of Title and Url fields
    const addField = () => {
        setFields([...fields, { title: '', url: '' }]);
    };

    // Remove the last set of fields
    const removeLastField = () => {
        const newFields = [...fields];
        newFields.pop();
        setFields(newFields);
    };
    const handleURLSubmit = async(e)=>{
        try {
            e.preventDefault();
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}api/v1/visual/visual`,{data:fields})
            setSuccessMessages(prev=>({...prev,tab:'header links updated!'}));
            setFields([{ title: '', url: '' }])
            setTimeout(()=>setSuccessMessages(prev=>({...prev,tab:''})),3000)
        } catch (error) {
            console.log('err',error);
            
        }
        
    }

    return (
        <>
            <Form encType='multipart/form-data' onSubmit={handleSubmit}>
            <h3>Update logo </h3>
                <Row className="align-items-center">
                    <Col xs={12} md={8}>
                        <Form.Group controlId="formFile">
                            <Form.Label>Upload an Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={4} className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
                        <Button variant="primary" type="submit" disabled={!selectedFile} className="mt-md-4 w-100 w-md-auto">
                            Submit
                        </Button>
                    </Col>
                </Row>

                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                {successMessages.fileUpload && <Alert variant="success" className="mt-3">{successMessages.fileUpload}</Alert>}
            </Form>
            {/* header update */}
            
            <Form className='mt-md-5 mt-3' onSubmit={handleHeaderSubmit}>
            <h3>Header Name Update</h3>
                <Row className='align-item-center mt-3'>
                    <Col md={8}>
                        <Form.Group>
                            <Form.Label>Enter new header text</Form.Label>
                            <Form.Control
                                type="text"
                                ref={headerRef}
                            />
                        </Form.Group></Col>
                    <Col xs={12} md={4} className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
                        <Button variant="primary" type="submit" className="mt-md-4 w-100 w-md-auto"  >
                            update
                        </Button>
                    </Col>
                </Row>
                {errorMessageN && <Alert variant="danger" className="mt-3">{errorMessageN}</Alert>}
                {successMessageN && <Alert variant="success" className="mt-3">{successMessageN}</Alert>}
            </Form>
            <Form className='mt-md-5 mt-3' onSubmit={handleURLSubmit}>
            <h3>Update URL </h3>
            <h6>Note: please make sures that URL must contains http:// or https://</h6>
                {fields.map((field, index) => (
                    <Row className="align-item-center mt-3" key={index}>
                        <Col md={8}>
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={field.title}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                />
                                <Form.Label>Url</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="url"
                                    value={field.url}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                ))}
                <Row className="mt-3 ">
                    <Col md={8}>
                    {successMessages.tab && <Alert variant="success" className="mt-3">{successMessages.tab}</Alert>}
                        <Button
                            variant="primary"
                            type="submit"
                            className='me-1'
                        >
                            Update
                        </Button>
                        <Button variant="success" onClick={addField} >
                            Add Field
                        </Button>
                        {fields.length > 1 && (
                            <Button variant="danger" className="ms-1" onClick={removeLastField}>
                                Remove Last Field
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
        </>


    );
};

export default Appearance
