import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { fetchNews } from '../../utils/fetchNews';
import { MIN_DATE, MAX_DATE, currentDateFinder } from '../../utils/date';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';

import './pdf.css';
import Header from '../header';
import { Footer } from '../../pages/footer';
import ArchiveComponent from '../archive/Archive';
import Logo from '../logo/Logo';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const PdfComponent = () => {
    const dateRef = useRef(null);
    const [pdfPath, setPdfPath] = useState('./mypdf.pdf');
    const [date, setDate] = useState(currentDateFinder());
    useEffect(() => {
        fetchNews(`${process.env.REACT_APP_BACKEND_URL}api/v1/client/pdf`, date)
            .then(url => (setPdfPath(url)))
            .catch(err => console.log(`error:${err}`)
            )
        setPageNumber(1)
    }, [date]);
    const inputRef = useRef(null);

    const handleIconClick = () => {
        if (inputRef.current) {
            inputRef.current.focus(); // Focus on the input field
            inputRef.current.click(); // Trigger the click event
        }
    };

    const handleSetPdf = (e) => {
        e.preventDefault();
        const customDateRef = dateRef.current.value;
        setDate(customDateRef);
    };


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    // Handle window resize for responsive PDF rendering
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Determine PDF width based on screen size
    const getPdfWidth = () => {
        const colElement = document.querySelector('.test');
    const parentWidth = colElement?.offsetWidth
    return parentWidth;
    };

    return (
        <Container className='background mt-3'>
            <Logo/>
            <Container className='containerBackgound'>
                <Header />
                <Document
                    file={pdfPath || './mypdf.pdf'}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {/* for strip */}
                    <ArchiveComponent
                        total={numPages}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        dateRef={dateRef}
                        handleSetPdf={handleSetPdf}
                        getPdf={pdfPath}
                    />

                    {/* Main PDF Page Section */}
                    <Row className="main-section">
                        <Col xs={12} md={3}>
                            <Container className="testClass d-none d-md-block">
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        className={`sidebar-x ${index + 1 === pageNumber ? 'sidebar-x-active' : ''}`}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        width={190} // Fixed width for preview
                                        onClick={() => { setPageNumber(index + 1); }}
                                    />
                                ))}
                            </Container>
                        </Col>

                        <Col xs={12} md={9} className='p-2 test'>
                            <Page
                                pageNumber={pageNumber}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                width={getPdfWidth()} // Dynamic width
                            />
                        </Col>
                    </Row>
                </Document>
            </Container>
            <Footer />
        </Container>
    );
}

export default PdfComponent;
