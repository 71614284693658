import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import './logo.css'
const Logo = () => {
  const [data, setData] = useState(() => {
    const storedData = localStorage.getItem('data');
    return storedData ? JSON.parse(storedData) : null; 
  });
  return (
    <Container className='containerHeight'>
        <img src={data?.headerImage || './logo.png'} alt="Logo" />
    </Container>
  )
}

export default Logo
