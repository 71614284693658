import axios from 'axios'
export const fetchNews = async(url,date)=>{
    // fetch news from server
    try {
        const response = await axios.post(url,{date})
        return response?.data?.data?.path;
    } catch (error) {
        return null;
    }
}

