export const MIN_DATE = '2024-09-01';

export const currentDateFinder = ()=>{
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear())
    
    return `${year}-${month}-${day}`;
}

export const dateValidator = (date) => {

    const [currentYear, currentMonth, currentDay] = currentDateFinder()?.split('-').map(Number); 
  
    // Define the minimum valid date
    const minDate = MIN_DATE.split('-').map(Number);
    const [minYear, minMonth, minDay] = minDate;
  
    // Split and convert the user-provided date to numbers
    const [year1, month2, day3] = date.split('-').map(Number);
  
    // Check if the date is within the valid range
    if (
      (year1 > minYear || (year1 === minYear && month2 > minMonth) || (year1 === minYear && month2 === minMonth && day3 >= minDay)) &&
      (year1 < currentYear || (year1 === currentYear && month2 < currentMonth) || (year1 === currentYear && month2 === currentMonth && day3 <= currentDay))
    ) 
        return true;
    else 
        return false;
  };

export const MAX_DATE = currentDateFinder();