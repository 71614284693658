import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { Footer } from './pages/footer';
import Header from './components/header'
import AppRoutes from './routes/AppRoutes';
function App() {
  return (
      <Router>
      {/* <Header/> */}
      <AppRoutes/>
      {/* <Footer/> */}
    </Router>
  );
}

export default App;
