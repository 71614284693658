import React, { useEffect, useState } from 'react'
import './home.css'
import { PdfComponent } from '../../components/pdf';
import Social from '../../components/social/Social';
const Home = () => {

  return (
    <>
    <Social/>
    <PdfComponent/>
    </>
  )
}

export default Home;
