import {Routes,Route, Link} from 'react-router-dom';
import Home from '../pages/home';

import React from 'react'
import {NotFound} from '../pages/not-found';
import { Categories } from '../components/categories';
import AdminDashboard from '../pages/admin/adminDashboard';
import { PasswordComp } from '../components/passwordComp';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>}  />
        <Route path='*' element={<NotFound/>}  />
        <Route path='/admin' element={<AdminDashboard/>}/>
        <Route path='/admin/forgot-password' element={<PasswordComp/>}/>
        <Route path='/category/:id' element={<Categories/>}/>
    </Routes>
  )
}

export default AppRoutes
