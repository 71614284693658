import React from 'react'
import './footer.css'
const Footer = () => {
  return (
    <div className=' d-block footer'>
    <h5 >@ all copyrights reserved by vadal-varta</h5>
    </div>
  )
}

export default Footer
