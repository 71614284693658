  import React, { useRef, useState } from 'react';
  import { useDispatch } from 'react-redux';
  import axios from 'axios';
  import './passwordComp.css';
  import { Row, Col, Form, Container, Button, Alert, Spinner } from 'react-bootstrap';

  const PasswordComp = () => {
    const [step, setStep] = useState(1);  // Step to manage the form flow
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);  // Loader state
    const [email,setEmail] = useState(null)
    const dispatch = useDispatch();
    const emailRef = useRef(null);
    const otpRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    
    // Handle sending OTP after email input
    const handleSendOTP = async (e) => {
      e.preventDefault();
      const email = emailRef.current.value;
      setEmail(email);
      // dispatch(emailUpdater(email));
      setIsLoading(true);  // Start loader
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/forgot-password`, { email });
        if (response.data.success) {
          setStep(2);  // Move to OTP input step
          setSuccessMessage('OTP sent to your email.');
          setTimeout(()=>setSuccessMessage(null),3000)
        }
      } catch (error) {
        console.log(error.message);
        setError(`${error?.response?.data?.error} Please try again`);
        setTimeout(()=>setError(null),3000);
      } finally {
        setIsLoading(false);  // Stop loader
      }
    };

    // Handle OTP verification
    const handleVerifyOTP = async (e) => {
      e.preventDefault();
      const otp = otpRef.current.value;
      setIsLoading(true);  // Start loader

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/verify-otp`, { email, otp });
        if (response?.data?.success) {

          setStep(3); 
          setSuccessMessage('OTP verified successfully');
          setTimeout(()=>setSuccessMessage(null),3000);
        } else {
          setError('Invalid OTP. Please try again.');
          setTimeout(()=>setError(null),3000);
        }
      } catch (error) {
        setError(`${error?.response?.data?.error}`);
        setTimeout(()=>setError(null),3000);
      } finally {
        setIsLoading(false);  // Stop loader
      }
    };

    // Handle resetting the password
    const handleResetPassword = async (e) => {
      e.preventDefault();
      const newPassword = newPasswordRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;

      if (newPassword !== confirmPassword) {
        setError('Passwords do not match.');
        setTimeout(()=>setError(null),3000);
        return;
      }

      setIsLoading(true);  // Start loader
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/admin/reset-password`, { email, newPassword });
        if (response.data.success) {
          setSuccessMessage('Password updated successfully.');
          setTimeout(()=>setSuccessMessage(null),3000);
          setStep(1);  // Reset form to the initial step
        } else {
          setError('Failed to reset password. Please try again.');
          setTimeout(()=>setError(null),3000);
          
        }
      } catch (error) {
        setError('Error resetting password: ' + error.message);
        console.log(error);
        
        setTimeout(()=>setError(null),3000);
      } finally {
        setIsLoading(false);  // Stop loader
      }
    };

    return (
      <Container >
        <Row className="justify-content-md-center">
          <Col xs={12} sm={10} md={6} xxl={5}>
            <h2 className="text-center">Forgot Password</h2>
            
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form onSubmit={step === 1 ? handleSendOTP : step === 2 ? handleVerifyOTP : handleResetPassword}>
              
              {/* Step 1: Email Input */}
              {step === 1 && (
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control 
                    ref={emailRef}
                    type="email" 
                    placeholder="Enter email"
                    required
                  />
                </Form.Group>
              )}

              {/* Step 2: OTP Input */}
              {step === 2 && (
                <Form.Group controlId="formBasicOTP">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control 
                    ref={otpRef}
                    type="number" 
                    placeholder="Enter OTP"
                    required
                  />
                </Form.Group>
              )}

              {/* Step 3: Password Reset */}
              {step === 3 && (
                <>
                  <Form.Group controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control 
                      ref={newPasswordRef}
                      type="password" 
                      placeholder="Enter new password"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control 
                      ref={confirmPasswordRef}
                      type="password" 
                      placeholder="Confirm new password"
                      required
                    />
                  </Form.Group>
                </>
              )}

              <Button variant="primary" type="submit" className="w-100 mt-4" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Processing...
                  </>
                ) : step === 1 ? 'Send OTP' : step === 2 ? 'Verify OTP' : 'Reset Password'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  };

  export default PasswordComp;
