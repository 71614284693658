import { init } from "../init";

export const appReducer = (state=init,action)=>{
    switch(action.type){
        case 'IS_LOGIN':
            return {
                ...state,
                isLogin:action.payload
            }
            case 'EMAIL_UPDATE':
            return {
                ...state,
                email:action.payload
            }
        default: return state;
    }
}